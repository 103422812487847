<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input v-model="searchForm.username" placeholder="名称" clearable />
      </el-form-item>

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button @click="dialogVisible = true" v-if="hasAuth('sys:user:save')"
          >新增</el-button
        >
      </el-form-item>

      <!-- <el-popconfirm title="确定批量删除吗？" @confirm="delHandle(null)">
      <template #reference>
        <el-button  :disabled="delBtnStatu" v-if="hasAuth('sys:user:delete')">
          批量删除
        </el-button>
      </template>
    </el-popconfirm> -->
      <el-form-item>
        <el-popconfirm title="确定批量删除吗？" @confirm="delHandle(null)">
          <template #reference>
            <el-button
              type="danger"
              size="medium"
              :icon="Delete"
              :disabled="delBtnStatu"
            >
              批量删除
            </el-button>
          </template>
        </el-popconfirm>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      border
      stripe
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column label="头像" width="50">
        <template #default="scope">
          <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
          <!--<el-avatar size="small" :src="require('@/assets/logo.jpg')"></el-avatar>-->
        </template>
      </el-table-column>
      <el-table-column prop="username" label="用户名" width="120">
      </el-table-column>
      <el-table-column prop="name" label="用户" width="120"> </el-table-column>
      <el-table-column prop="code" label="角色名称">
        <template #default="scope">
          <el-tag
            size="small"
            type="info"
            v-for="item in scope.row.sysRoles"
            :key="item.id"
            >{{ item.name }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="email" label="邮箱"> </el-table-column>
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="statu" label="状态">
        <template #default="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="created" width="200" label="创建时间">
      </el-table-column>
      <el-table-column prop="icon" width="260px" label="操作">
        <template #default="scope">
          <el-button type="text" @click="roleHandle(scope.row.id)"
            >分配角色</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <el-button
            type="text"
            @click="repassHandle(scope.row.id, scope.row.username)"
            >重置密码</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <el-button type="text" @click="editHandle(scope.row.id)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <el-popconfirm
            title="这是一段内容确定删除吗？"
            @confirm="delHandle(scope.row.id)"
          >
            <template #reference>
              <el-button type="text">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="position: absolute; bottom: 1%; right: 1%">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[10, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total"
      >
      </el-pagination>
    </div>

    <!--新增对话框-->
    <el-dialog
      title="用户信息"
      v-model="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="用户名" prop="username" label-width="100px">
          <el-input v-model="editForm.username" autocomplete="off"></el-input>
          <el-alert
            title="初始密码为88888"
            :closable="false"
            type="info"
            style="line-height: 12px"
          ></el-alert>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" label-width="100px">
          <el-input v-model="editForm.email" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone" label-width="100px">
          <el-input v-model="editForm.phone" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="状态" prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="0">禁用</el-radio>
            <el-radio :label="1">正常</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="resetForm('editForm')"><Refresh/>重置</el-button>
          <el-button type="primary" @click="submitForm('editForm')"
            ><CircleCheck/>提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="分配角色" v-model="roleDialogFormVisible" width="600px">
      <el-form :model="roleForm">
        <el-tree
          :data="roleTreeData"
          show-checkbox
          ref="roleTree"
          :check-strictly="checkStrictly"
          node-key="id"
          :default-expand-all="true"
          :props="defaultProps"
        >
        </el-tree>
      </el-form>
      <span class="dialog-footer">
        <el-button @click="roleDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/http/HTTP";
import { Edit, Delete, Search, CirclePlus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  name: "User",
  data() {
    return {
      searchForm: {},
      delBtnStatu: true,

      total: 0,
      size: 10,
      current: 1,

      dialogVisible: false,
      editForm: {},
      tableData: [],
      editFormRules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        statu: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
      multipleSelection: [],
      roleDialogFormVisible: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleForm: {},
      roleTreeData: [],
      treeCheckedKeys: [],
      checkStrictly: true,
    };
  },
  created() {
    this.getUserList();

    axios.get("/sys/role/list").then((res) => {
      this.roleTreeData = res.data.records;
    });
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    //权限选择默认事件，用于获取选中的id
    handleSelectionChange(val) {
      //console.log(val)
      this.multipleSelection = val;
      this.delBtnStatu = val.length == 0;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val;
      //改变页数后再次调用页面数据
      this.getUserList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val;
      this.getUserList();
    },
    //清空表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm = {};
    },
    handleClose() {
      this.resetForm("editForm");
    },
    getUserList() {
      axios
        .get("/sys/user/list", {
          params: {
            username: this.searchForm.username,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.records;
          console.log(this.tableData);
          this.size = res.data.size;
          this.total = res.data.total;
          this.current = res.data.current;
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //通过editForm判断是否存在id，有id就更新，没有id就执行存储
          axios
            .post(
              "/sys/user/" + (this.editForm.id ? "update" : "save"),
              this.editForm
            )
            .then((res) => {
              //console.log(res)
              ElMessage({
                showClose: true,
                message: "操作成功!",
                type: "success",
              });
              this.getUserList();
              this.dialogVisible = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    editHandle(id) {
      console.log(id);
      axios.get("/sys/user/info/" + id).then((res) => {
        this.editForm = res.data;
        this.dialogVisible = true;
      });
    },
    delHandle(id) {
      var ids = [];

      //批量删除并没有传入id
      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }
      //console.log(ids)
      axios.post("/sys/user/delete", ids).then((res) => {
        console.log(res);
        ElMessage({
          showClose: true,
          message: "删除操作成功!",
          type: "success",
        });
        this.getUserList();
      });
    },
    roleHandle(id) {
      this.roleDialogFormVisible = true;

      axios.get("/sys/user/info/" + id).then((res) => {
        console.log(res);
        this.roleForm = res.data;
        let roleIds = [];
        res.data.sysRoles.forEach((row) => {
          roleIds.push(row.id);
        });
        this.$refs.roleTree.setCheckedKeys(roleIds);
      });
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys();
      //console.log(menuIds)
      console.log(this.roleForm);
      console.log(roleIds);
      axios.post("/sys/user/role/" + this.roleForm.id, roleIds).then((res) => {
        ElMessage({
          showClose: true,
          message: "修改成功!",
          type: "success",
        });
        this.getUserList();
        this.roleDialogFormVisible = false;
      });
    },
    repassHandle(id, username) {
      ElMessageBox.confirm(
        "将重置用户【" + username + "】的密码，是否继续？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then((res) => {
        axios.post("/sys/user/repass", id).then((res) => {
          ElMessage({
          showClose: true,
          message: "密码修改成功!",
          type: "success",
        });
        });
      });
    },
    hasAuth(perm) {
      console.log("perm=",perm,"sessionStorage=",sessionStorage);
      const authority = sessionStorage.getItem("permList");
      return authority.indexOf(perm) > -1;
    },
  },
};
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 22px;
}
</style>
